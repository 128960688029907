var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.workspaceMemberIntegrationsLoaded
        ? _c("Button", {
            attrs: { text: _vm.triggerText, type: "white" },
            on: { click: _vm.triggerFunction },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function() {
                    return [
                      _c("img", {
                        staticClass: "calendar-connect-trigger-img",
                        attrs: { src: _vm.integrationObject.image, alt: "" }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1887350098
            )
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.modalVisible,
            "can-cancel": ["escape", "outside"]
          },
          on: {
            close: function() {
              return (_vm.step ? _vm.cancelTemplateSelect : _vm.close)()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "calendar-connect",
              class: { restricted: !_vm.step }
            },
            [
              _c(
                "div",
                { staticClass: "calendar-connect-header" },
                [
                  _c("p", { staticClass: "calendar-connect-header-title" }, [
                    _vm._v(_vm._s(_vm.modalHeader))
                  ]),
                  _c("Button", {
                    attrs: { icon: "close", type: "grey", size: "xs" },
                    on: {
                      click: function() {
                        return (_vm.step
                          ? _vm.cancelTemplateSelect
                          : _vm.close)()
                      }
                    }
                  })
                ],
                1
              ),
              !_vm.step
                ? _c("div", { staticClass: "calendar-connect-content" }, [
                    _c(
                      "p",
                      { staticClass: "calendar-connect-content-header" },
                      [
                        _vm._v(
                          " Select which of your calendars we should use to automatically prepare meetings. These meetings will be visible here and a summary with generated assets will be sent to your email daily. "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "calendar-connect-content-list" },
                      [
                        _vm.loading
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "calendar-connect-content-list-loading"
                              },
                              [
                                _c("b-loading", {
                                  attrs: { active: "", "is-full-page": false }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(_vm.calendars, function(calendar) {
                          return _c(
                            "div",
                            {
                              key: calendar.uuid,
                              staticClass: "calendar-connect-content-list-item"
                            },
                            [
                              _vm._v(" " + _vm._s(calendar.name) + " "),
                              _c("Button", {
                                attrs: {
                                  text: calendar.sync ? "Disconnect" : "Sync",
                                  type: calendar.sync ? "white" : "primary",
                                  loading: _vm.calendarSyncLoading.includes(
                                    calendar.uuid
                                  )
                                },
                                on: {
                                  click: function() {
                                    return _vm.syncCalendar(calendar)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    ),
                    !_vm.loading && _vm.connectedCalendars.length
                      ? _c("div", { staticClass: "calendar-connect-outputs" }, [
                          _c(
                            "p",
                            { staticClass: "calendar-connect-outputs-header" },
                            [_vm._v(" Manage generated outputs ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "calendar-connect-outputs-list" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "calendar-connect-outputs-list-item"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "calendar-connect-outputs-list-item-name"
                                    },
                                    [_vm._v(" Meeting preparation ")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "calendar-connect-outputs-list-item-count"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedPreMeetingTemplates
                                              .length
                                          ) +
                                          " / 3 selected "
                                      )
                                    ]
                                  ),
                                  _c("Button", {
                                    staticClass:
                                      "calendar-connect-outputs-list-item-btn",
                                    attrs: {
                                      text: "Manage",
                                      disabled: _vm.postSaveLoading,
                                      loading: _vm.preSaveLoading
                                    },
                                    on: {
                                      click: function() {
                                        return _vm.selectTemplates("before")
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.isUman
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "calendar-connect-outputs-list-item"
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "calendar-connect-outputs-list-item-name"
                                        },
                                        [_vm._v(" Meeting follow-up ")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "calendar-connect-outputs-list-item-count"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedPostMeetingTemplates
                                                  .length
                                              ) +
                                              " / 3 selected "
                                          )
                                        ]
                                      ),
                                      _c("Button", {
                                        staticClass:
                                          "calendar-connect-outputs-list-item-btn",
                                        attrs: {
                                          text: "Manage",
                                          disabled: _vm.preSaveLoading,
                                          loading: _vm.postSaveLoading
                                        },
                                        on: {
                                          click: function() {
                                            return _vm.selectTemplates("after")
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                : _c(
                    "div",
                    { staticClass: "calendar-connect-templates" },
                    [
                      _c("MeetingOutputsSelect", {
                        attrs: {
                          "force-selected": (_vm.templateSelectType === "before"
                            ? _vm.selectedPreMeetingTemplates
                            : _vm.selectedPostMeetingTemplates
                          ).map(function(t) {
                            return t.uuid
                          }),
                          "max-selected": 3
                        },
                        on: { select: _vm.editSelectedTemplates }
                      })
                    ],
                    1
                  ),
              _vm.step
                ? _c("div", { staticClass: "calendar-connect-footer" }, [
                    _c("div", { staticClass: "calendar-connect-footer-left" }, [
                      _c(
                        "p",
                        { staticClass: "calendar-connect-footer-left-count" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tempSelection.length) +
                              " output" +
                              _vm._s(
                                _vm.tempSelection.length === 1 ? "" : "s"
                              ) +
                              " selected "
                          )
                        ]
                      ),
                      _c(
                        "p",
                        { staticClass: "calendar-connect-footer-left-max" },
                        [_vm._v("(max. 3)")]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "calendar-connect-footer-btns" },
                      [
                        _c("Button", {
                          attrs: { text: "Close", type: "white" },
                          on: { click: _vm.cancelTemplateSelect }
                        }),
                        _c("Button", {
                          attrs: { text: "Save", type: "primary" },
                          on: { click: _vm.saveTemplates }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }